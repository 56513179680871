import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get, post } from '../../../services/api.services';
import { API } from '../../../constants';

import {
  Box,
  Button,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { displayName } from '../../../utils/profile';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function ContactMeModal({ open, profile, handleClose, product }) {
  const token = useSelector((state) => state.account.token);

  const [openSB, setOpenSB] = useState(false);
  // input state
  const [productOfInterest, setProductOfInterest] = useState(product?.title || '');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fullName || !email || !message) {
      setErrorMessage('Please fill out all required fields.');
      return;
    }

    // add api logic for email (console.log is placeholder)

    const data = {
      product_title: productOfInterest,
      contact_name: fullName,
      contact_email: email,
      contact_phone: phone,
      contact_message: message,
    };

    const result = await post(
      `${API.PROFILE}${profile.id}/inquiry/`,
      data,
      token,
    );

    // clear out the user's responses
    setProductOfInterest('');
    setFullName('');
    setEmail('');
    setPhone('');
    setMessage('');

    // open Snackbar
    setOpenSB(true);

    // close modal after submission
    handleClose();
  };

  const formatPhoneNumber = (value) => {
    // remove all non-digit characters
    const phoneNumber = value.replace(/\D/g, '');

    // format phone number into 123-456-7890
    if (phoneNumber.length > 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6, 10)}`;
    } else if (phoneNumber.length > 3) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}`;
    } else {
      return phoneNumber;
    }
  };

  // handle phone number input change
  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSB}
        autoHideDuration={4000}
        onClose={() => setOpenSB(false)}
        message="Email sent"
      />
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            sx={{ marginBottom: '10px' }}
          >
            Contact {profile.business_name || displayName(profile.first_name, profile.last_name)}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="Service / Product of Interest"
                fullWidth
                value={productOfInterest}
                onChange={(e) => setProductOfInterest(e.target.value)}
              />

              <TextField
                label="Full Name"
                fullWidth
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />

              <TextField
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <TextField
                label="Phone Number (Optional)"
                type="tel"
                fullWidth
                value={phone}
                onChange={handlePhoneChange}
                inputProps={{ maxLength: 12 }}
                placeholder="123-456-7890"
              />

              <TextField
                label="Message"
                multiline
                rows={3}
                inputProps={{ maxLength: 200 }}
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                helperText={`${message.length}/200 characters`}
              />

              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}

              <Button type="submit" variant="contained" color="primary">
                Send
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}
